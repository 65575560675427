import React, { useCallback, useEffect, useState } from 'react'
import { mintStore } from 'stores'
import Footer from '../Footer/Footer'
import { Icon } from 'components'
import { MINT_WIDGET_VIEWS } from 'mainConstants'
import { MintWidgetViewProps } from 'components/MintWidget/MintWidgetTypes'
import styles from './Done.module.scss'

function Done({ goTo }: MintWidgetViewProps) {
  const restart = useCallback(() => {
    mintStore.reset()
    goTo(MINT_WIDGET_VIEWS.UPLOAD)
  }, [])
  return (
    <div className={styles.root}>
      <div>
        <Icon className={styles.ok} type="ok" />
        <div className={styles.title}>You're done!</div>
        <div className={styles.desc}>Your NFT has been successfully minted!</div>
        <div className={styles.link}>
          View on Etherscan <Icon type="link" />
        </div>
      </div>
      <Footer label="Mint Another NFT" onClick={restart} buttonType="grey" />
    </div>
  )
}

export default Done
