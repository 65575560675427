import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { DragAndDrop } from 'alteon-component-library'
import { mintStore } from 'stores'
import { helpers } from 'utils'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { MintWidgetViewProps } from 'components/MintWidget/MintWidgetTypes'
import { MB_100, MINT_WIDGET_VIEWS } from 'mainConstants'
import styles from './Upload.module.scss'

const ERROR_TYPE = 'File type is not supported.'
const ERROR_SIZE = 'Max size allowed: 100 MB'

function Upload({ goTo }: MintWidgetViewProps) {
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState('')
  const { image, setImage } = mintStore

  const handleChange = async (files: File[]) => {
    setIsUploading(true)
    const file = files[0]
    const { name, size, type } = file
    const acceptedImageTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/tiff',
      'image/webp',
      'image/svg',
      'image/svg+xml',
    ]

    if (!acceptedImageTypes.includes(type)) {
      setIsUploading(false)
      setError(ERROR_TYPE)
      return
    } else if (size > MB_100) {
      setIsUploading(false)
      setError(ERROR_SIZE)
      return
    }

    if (file) {
      setImage({ name, src: URL.createObjectURL(file), size: helpers.formatBytes(size) })
    } else {
      setImage({ name: '', src: '', size: '' })
    }

    setIsUploading(false)
  }

  const handleOnDropFile = (files: File[]) => {
    if (files.length > 1) {
      alert('Please drag and drop only single file')
    }
  }

  return (
    <div className={styles.root}>
      <div>
        <Header icon="upload" text="Upload" />
        <div className={styles.desc}>Upload any image you want to mint as an NFT. Max size: 100 MB</div>
        <div className={styles.dropArea}>
          {isUploading ? (
            <div>isUploading</div>
          ) : image.src ? (
            <div className={styles.imageContainer}>
              <img className={styles.image} src={image.src} alt="" />
            </div>
          ) : (
            <DragAndDrop
              containerClassName={styles.drop}
              onUpload={handleChange}
              onDrop={handleOnDropFile}
              count={1}
              hoverText=""
            >
              <div className={styles.dropText}>Click or drag to upload</div>
            </DragAndDrop>
          )}
          {error && (
            <div className={styles.error}>
              Error uploading.
              <br />
              {error}
            </div>
          )}
        </div>
      </div>
      <Footer onClick={() => goTo(MINT_WIDGET_VIEWS.EDIT)} disabled={!image.src} />
    </div>
  )
}

export default observer(Upload)
