import React from 'react'
import cx from 'classnames'
import { Button as LibButton } from 'alteon-component-library'
import styles from './Button.module.scss'

interface ButtonProps {
  className?: string
  disabled?: boolean
  label?: string
  onClick: () => void
  type?: string
}

function Button({ className, disabled = false, label, onClick, type = 'primary' }: ButtonProps) {
  return <LibButton className={cx(styles.root, styles[type], className)} label={label} onClick={onClick} disabled={disabled} />
}

export default Button
