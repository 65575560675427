import React from 'react'
import { ReactComponent as alteon } from './svg/alteon.svg'
import { ReactComponent as caretDown } from './svg/caret-down.svg'
import { ReactComponent as coinbase } from './svg/coinbase.svg'
import { ReactComponent as hexagon } from './svg/hexagon.svg'
import { ReactComponent as link } from './svg/link.svg'
import { ReactComponent as metamask } from './svg/metamask.svg'
import { ReactComponent as ok } from './svg/ok.svg'
import { ReactComponent as operaCrypto } from './svg/opera-crypto.svg'
import { ReactComponent as question } from './svg/question.svg'
import { ReactComponent as upload } from './svg/upload.svg'
import { ReactComponent as wallet } from './svg/wallet.svg'
import { ReactComponent as walletLg } from './svg/walletLg.svg'

export const SVG_MAP: { [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
  alteon,
  caretDown,
  coinbase,
  hexagon,
  link,
  metamask,
  ok,
  operaCrypto,
  question,
  upload,
  wallet,
  walletLg,
}
