// import { createAlchemyWeb3 } from "@alch/alchemy-web3"

export function getWalletName() {
  if (window.ethereum) {
    // const metaMask = window.ethereum.providers.find((provider: any) => provider.isMetaMask);
    // const coinbase = window.ethereum.providers.find((provider: any) => provider.isMetaMask);
    console.log(window.ethereum)
    if (window.ethereum.isMetaMask) {
      return 'metamask'
    } else if (window.ethereum.isOpera) {
      return 'opera'
    }
    return 'opera'
  }
}

export async function connectWallet() {
  console.log('window.ethereum', window.ethereum)
  if (window.ethereum) {
    // const metaMask = window.ethereum.providers.find((provider: any) => provider.isMetaMask);
    // const coinbase = window.ethereum.providers.find((provider: any) => provider.isMetaMask);
    // console.log(window.ethereum.providers)
    // if (window.ethereum.isConnected()) {
    //   return
    // }
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })
      // const walletArray = await window.ethereum.request({
      //   method: 'wallet_requestPermissions',
      // })
      console.log(addressArray)
      // const obj = {
      //   status: "Done",
      //   address: addressArray[0],
      // }
      // return obj
    } catch (err: any) {
      return {
        address: '',
        status: 'Error ' + err.message,
      }
    }
  } else {
    return {
      address: '',
      status: 'You must install a virtual Ethereum wallet, in your browser.',
    }
  }
}

export async function getCurrentWalletConnected() {
  if (window.ethereum) {
    // if (window.ethereum._rpc) {
    //   return true
    // }
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_accounts',
      })
      if (addressArray.length > 0) {
        return true
      } else {
        return false
      }
    } catch (err: any) {
      return false
    }
  } else {
    return false
  }
}
