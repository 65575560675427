import React from 'react'
import { Icon } from 'components'
import styles from './AuthorLink.module.scss'

const AUTHOR = 'Kristin Simmons'
const LINK = 'https://www.kristinsimmonsart.com/'

function AuthorLink() {
  return (
    <a className={styles.root} href={LINK} target="_blank" rel="noreferrer">
      <div className={styles.avatar} />
      <div className={styles.info}>
        Minted by
        <div className={styles.author}>
          {AUTHOR}
          <Icon type="link" className={styles.link} />
        </div>
      </div>
    </a>
  )
}

export default AuthorLink
