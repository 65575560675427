import axios from 'axios'
import { Mints } from './mintTypes'

function load(id: string) {
  return axios.get<Mints>(`/mint/${id}/data`).then((payload) => payload.data)
}

function save(id: string, data: Mints) {
  return axios.put<Mints>(`/mint/${id}/data`, data).then((payload) => payload.data)
}

const mintServices = { load, save }
export default mintServices
