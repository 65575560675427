import React, { useMemo } from 'react'
import cx from 'classnames'
import Views from './Views'
import { MINT_WIDGET_VIEWS } from 'mainConstants'
import styles from './MintWidget.module.scss'

interface MintWidgetProps {
  view: string
  setView: (view: MINT_WIDGET_VIEWS) => void
  wideView?: boolean
}


function MintWidget({ view, setView, wideView }: MintWidgetProps) {
  const CurrentView = useMemo(() => Views[view], [view])

  return (
    <div className={cx(styles.root, {[styles.wide]: wideView})}>
      <CurrentView goTo={setView} />
    </div>
  )
}

export default MintWidget
