export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function calculateContentHeight( ta: HTMLTextAreaElement, lineHeight: number ): number {
  let origHeight = ta.style.height,
    height = ta.offsetHeight,
    scrollHeight = ta.scrollHeight,
    overflow = ta.style.overflow;
  /// only bother if the ta is bigger than content
  if ( height >= scrollHeight ) {
    // /// check that our browser supports changing dimension
    // /// calculations mid-way through a function call...
    ta.style.height = (height + lineHeight) + 'px';
    // /// because the scrollbar can cause calculation problems
    // ta.style.overflow = 'hidden';
    // /// by checking that scrollHeight has updated
    if ( scrollHeight < ta.scrollHeight ) {
      /// now try and scan the ta's height downwards
      /// until scrollHeight becomes larger than height
      while (ta.offsetHeight >= ta.scrollHeight) {
        ta.style.height = (height -= lineHeight)+'px';
      }
      /// be more specific to get the exact height
      while (ta.offsetHeight < ta.scrollHeight) {
        ta.style.height = (++height)+'px';
      }
      /// reset the ta back to it's original height
      ta.style.height = origHeight;
      /// put the overflow back
      ta.style.overflow = overflow;
      return height;
    }
  } else {
    return scrollHeight;
  }

  return height;
}
