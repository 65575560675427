import React, { useState } from 'react'
import { AuthorLink, Header, Icon, MintWidget } from 'components'
import styles from './Mint.module.scss'
import {MINT_WIDGET_VIEWS} from "../../mainConstants";

export interface MintWidgetViewProps {
  goTo: (view: MINT_WIDGET_VIEWS) => void
}

function Mint() {
  const [widgetView, setWidgetView] = useState(MINT_WIDGET_VIEWS.WALLET)
  const wideView = widgetView === MINT_WIDGET_VIEWS.EDIT

  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.widget}>
        <MintWidget view={widgetView} setView={setWidgetView} wideView={wideView} />
        {!wideView &&
          <div className={styles.description}>
            <div className={styles.title}>Welcome to Alteon LaunchPad</div>
            <div className={styles.slogan}>
              Bring your
              <br />
              NFTs to life
            </div>
            <div className={styles.text}>
              Upload, mint, monetize. Alteon LaunchPad lets you can generate NFTs from any media file, instantly and
              securely, straight from your Opera Crypto Browser
            </div>
          </div>
        }
      </div>
      <div>
        <Icon type="question" className={styles.hint} />
        <AuthorLink />
      </div>
    </div>
  )
}

export default Mint
