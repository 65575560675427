import React, { useCallback, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import cx from 'classnames'
import { TagInput, TextArea, TextInput } from 'alteon-component-library'
import { mintStore } from 'stores'
import Header from '../Header/Header'
import { Button } from 'components'
import { helpers } from 'utils'
import { MintWidgetViewProps } from 'components/MintWidget/MintWidgetTypes'
import { MINT_WIDGET_VIEWS } from 'mainConstants'
import styles from './Edit.module.scss'

const LINE_HEIGHT = 24
const MIN_HEIGHT = LINE_HEIGHT * 1

function Edit({ goTo }: MintWidgetViewProps) {
  const formRef = useRef<HTMLDivElement>(null)
  const {
    image: { name, src, size },
  } = mintStore
  const [scrollIsVisible, setScrollIsVisible] = useState(false)
  const [description, setDescription] = useState(``)
  const [descriptionHeight, setDescriptionHeight] = useState(`${MIN_HEIGHT}px`)

  const checkIfScrollAppeared = useCallback(() => {
    const newScrollIsVisible = (formRef.current &&
      formRef.current.scrollHeight > formRef.current.clientHeight) as boolean
    if (newScrollIsVisible !== scrollIsVisible) {
      setScrollIsVisible(newScrollIsVisible)
    }
  }, [formRef, scrollIsVisible])

  const onDescriptionChange = useCallback(
    (e: any) => {
      const height = helpers.calculateContentHeight(e.target, LINE_HEIGHT)
      const newHeight = height < MIN_HEIGHT ? MIN_HEIGHT : height
      setDescriptionHeight(`${newHeight}px`)
      setTimeout(checkIfScrollAppeared, 10)
    },
    [description, checkIfScrollAppeared]
  )

  useEffect(() => {
    checkIfScrollAppeared()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', checkIfScrollAppeared)

    return () => {
      window.removeEventListener('click', checkIfScrollAppeared)
    }
  }, [checkIfScrollAppeared])

  const formClassName = cx(styles.form, { [styles.withScroll]: scrollIsVisible })

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <Header icon="hexagon" text="Smart Contract" />
        <div className={styles.formWrapper} ref={formRef}>
          <div className={formClassName}>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={src} />
              <div className={styles.imageInfo}>
                <div>
                  <div className={styles.imageName}>{name}</div>
                  <div className={styles.imageSize}>{size}</div>
                </div>
                <div className={styles.imageLink}>Preview</div>
              </div>
            </div>
            <div className={styles.formItem}>
              <TextInput label="Name" />
            </div>
            <div className={styles.formItem}>
              <TextArea
                height={descriptionHeight}
                label="Description"
                onChange={onDescriptionChange}
                textAreaClassName={styles.area}
              />
              <div className={styles.line} />
            </div>
            <div className={styles.formItem}>
              <TextInput label="Quantity" />
            </div>
            <div className={styles.formItem}>
              <div className={styles.label}>Properties</div>
              <TagInput addLabel="Add" maxTagAllowed={5} tagType="tag" tags={[]} />
              <div className={styles.line} />
            </div>
            <div className={styles.formItem}>
              <div className={styles.label}>Blockchain*</div>
              <div className={styles.block}>
                <div className={styles.ethereum}>
                  <div className={styles.ethereumLogo} />
                </div>
                Ethereum
              </div>
              <div className={styles.line} />
            </div>
            <div className={styles.formItem}>Contract type: ERC-271</div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button label="Back" onClick={() => goTo(MINT_WIDGET_VIEWS.UPLOAD)} type="grey" />
        <Button label="Confirm" onClick={() => goTo(MINT_WIDGET_VIEWS.DONE)} className={styles.confirm} />
      </div>
    </div>
  )
}

export default observer(Edit)
