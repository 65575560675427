import React from 'react'
import { noop } from 'lodash'
import cx from 'classnames'
import { SVG_MAP } from './IconsMap'
import styles from './Icon.module.scss'

interface IconProps {
  type: string
  className?: string
  onClick?: () => void
}

function Icon({ type, className, onClick = noop }: IconProps) {
  const IconComponent = SVG_MAP[type]

  if (!IconComponent) {
    return null
  }

  return (
    <span key={type} className={cx(styles.root, className)} onClick={onClick}>
      <IconComponent key={type} />
    </span>
  )
}

export default Icon
