import React from 'react'
import { Icon } from 'components'
import styles from './Header.module.scss'

interface HeaderProps {
  icon: string
  text: string
}

function Header({ icon, text }: HeaderProps) {
  return (
    <div className={styles.root}>
      <Icon type={icon} className={styles.headerIcon} />
      {text}
    </div>
  )
}

export default Header
