import React, { useCallback, useEffect, useState } from 'react'
import { get } from 'lodash'
import cx from 'classnames'
import Footer from '../Footer/Footer'
import { Icon } from 'components'
import { MintWidgetViewProps } from 'components/MintWidget/MintWidgetTypes'
import { MINT_WIDGET_VIEWS } from 'mainConstants'
import { Web3Utils } from 'utils'
import styles from './Wallet.module.scss'

const TEXTS = {
  WALLET_CONNECTED: {
    title: 'Your wallet is connected',
    description: `Everything is ready -
        Let's start minting your NFTs`,
    label: 'Get Started',
  },
  WALLET_NOT_CONNECTED: {
    title: 'Your wallet is not connected',
    description: 'You need to have a wallet connected in order to mint your NFTs',
    label: 'Create or Restore wallet',
  },
}

function Wallet({ goTo }: MintWidgetViewProps) {
  const [walletConnected, setWalletConnected] = useState(false)

  useEffect(() => {
    Web3Utils.getCurrentWalletConnected().then(setWalletConnected)
    // setWalletConnected(true)
  }, [])

  const connect = useCallback(() => {
    if (get(window, 'ethereum.isOpera')) {
      window.location.reload()
    } else {
      Web3Utils.connectWallet()
    }
  }, [setWalletConnected])

  const onClick = walletConnected ? () => goTo(MINT_WIDGET_VIEWS.UPLOAD) : connect
  const { title, description, label } = walletConnected ? TEXTS.WALLET_CONNECTED : TEXTS.WALLET_NOT_CONNECTED

  return (
    <div className={styles.root}>
      <div>
        <Icon className={styles.wallet} type="walletLg" />
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{description}</div>
      </div>
      <Footer label={label} onClick={onClick} buttonClassName={cx({ [styles.cond]: !walletConnected })} />
    </div>
  )
}

export default Wallet
