import { makeObservable, observable } from 'mobx'
import { Image } from '../services/mint/mintTypes'
import api from '../services'

export class MintStore {
  service: typeof api.mint
  image: Image = { name: '', src: '', size: '' }
  loading = false

  constructor(MintService: typeof api.mint) {
    this.service = MintService
    makeObservable(this, { loading: observable, image: observable })
  }

  setImage = (image: Image) => {
    this.image = image
  }

  reset = () => {
    this.image = { name: '', src: '', size: '' }
  }
}

export const mintStore = new MintStore(api.mint)
