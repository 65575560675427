import React from 'react'
import { Button, Icon } from 'components'
import styles from './Footer.module.scss'

interface FooterProps {
  buttonClassName?: string
  buttonType?: string
  disabled?: boolean
  label?: string
  onClick: () => void
}

const LINK = 'https://alteon.io/'

function Footer({ buttonClassName = '', disabled = false, label = 'Continue', onClick, buttonType = 'primary' }: FooterProps) {
  return (
    <div className={styles.root}>
      <Button className={buttonClassName} label={label} onClick={onClick} disabled={disabled} type={buttonType} />
      <a className={styles.alteonLink} href={LINK} target="_blank" rel="noreferrer">
        <Icon type="alteon" className={styles.alteonLogo} />
        Learn more about Alteon.io
      </a>
    </div>
  )
}

export default Footer
