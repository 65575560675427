import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Mint } from './pages'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Mint />} />
    </Routes>
  )
}

export default App
